.o-typography--h1 {
	font-family: var(--font-headings);
}
.o-typography--h2 {
	color: var(--color-brand);
	font-family: var(--font-headings-alt);
}
.o-typography--h3 {
	color: var(--color-brand);
	font-family: var(--font-headings-alt);
}
.o-typography--h4 {
	color: var(--color-brand);
	font-family: var(--font-headings-alt);
}
.o-typography--h5 {
	color: var(--color-brand);
	font-family: var(--font-headings-alt);
}
.o-typography--h6 {
	color: var(--color-brand);
	font-family: var(--font-headings-alt);
}