@define-mixin hide  {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.u-hide { @mixin hide; }

@media (--palm) {
    .u-hide-palm { @mixin hide; }
}

@media (--lap) {
    .u-hide-lap { @mixin hide; }
}

@media (--lap-and-up) {
    .u-hide-lap-and-up { @mixin hide; }
}

@media (--portable) {
    .u-hide-portable { @mixin hide; }
}

@media (--desk) {
    .u-hide-desk { @mixin hide; }
}

