@media (--palm) {
    .u-1\/1-palm {
        lost-column: 1/1 flex;
    }
    .u-1\/2-palm {
        lost-column: 1/2 flex;
    }
    .u-1\/3-palm {
        lost-column: 1/3 flex;
    }
    .u-2\/3-palm {
        lost-column: 2/3 flex;
    }
    .u-1\/4-palm {
        lost-column: 1/4 flex;
    }
    .u-3\/4-palm {
        lost-column: 3/4 flex;
    }
}

@media (--lap) {
    .u-1\/1-lap {
        lost-column: 1/1 flex;
    }
    .u-1\/2-lap {
        lost-column: 1/2 flex;
    }
    .u-1\/3-lap {
        lost-column: 1/3 flex;
    }
    .u-2\/3-lap {
        lost-column: 2/3 flex;
    }
    .u-1\/4-lap {
        lost-column: 1/4 flex;
    }
    .u-3\/4-lap {
        lost-column: 3/4 flex;
    }
}

@media (--lap-and-up) {
    .u-1\/1-lap-and-up {
        lost-column: 1/1 flex;
    }
    .u-1\/2-lap-and-up {
        lost-column: 1/2 flex;
    }
    .u-1\/3-lap-and-up {
        lost-column: 1/3 flex;
    }
    .u-2\/3-lap-and-up {
        lost-column: 2/3 flex;
    }
    .u-1\/4-lap-and-up {
        lost-column: 1/4 flex;
    }
    .u-3\/4-lap-and-up {
        lost-column: 3/4 flex;
    }
}

@media (--portable) {
    .u-1\/1-portable {
        lost-column: 1/1 flex;
    }
    .u-1\/2-portable {
        lost-column: 1/2 flex;
    }
    .u-1\/3-portable {
        lost-column: 1/3 flex;
    }
    .u-2\/3-portable {
        lost-column: 2/3 flex;
    }
    .u-1\/4-portable {
        lost-column: 1/4 flex;
    }
    .u-3\/4-portable {
        lost-column: 3/4 flex;
    }
}

@media (--desk) {
    .u-1\/1-desk {
        lost-column: 1/1 flex;
    }
    .u-1\/2-desk {
        lost-column: 1/2 flex;
    }
    .u-1\/3-desk {
        lost-column: 1/3 flex;
    }
    .u-2\/3-desk {
        lost-column: 2/3 flex;
    }
    .u-1\/4-desk {
        lost-column: 1/4 flex;
    }
    .u-3\/4-desk {
        lost-column: 3/4 flex;
    }
}