label {
}

input,
textarea {
}

textarea {
    resize: vertical;
}

