.c-cta {
    background-color: var(--color-gray-light);
    border-bottom: .25em solid var(--color-gray);

    padding-top: 2em;
    padding-bottom: 1em;
}

	@media (--palm) {
		.c-cta__conjunction,
		.c-cta__button.c-btn {
			display: block;
			text-align: center;
		}
	}
