.c-pagination {
    border-top: .25rem solid var(--color-brand);
    padding-top: 1rem;
    margin-top: 2rem;

    lost-flex-container: row;
}

.c-pagination__link {
    font-size: 22px/rem;
    line-height: 1.25;
    font-weight: bold;
    color: var(--color-brand);
}

.c-pagination__col {
    lost-column: 1/2 flex;
}

.c-pagination__next {
    text-align: left;
}
.c-pagination__next {
    text-align: right;
}