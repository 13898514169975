
ul {
    list-style: square outside;
}

ul,
ol {
    margin-left: var(--base-spacing-unit);
    margin-bottom: 1em;
    margin-top: 1em;
}

li > {

    ul,
    ol {
        margin-left: var(--base-spacing-unit);
        margin-top: 0;
        margin-bottom: 0;

    }

}



dt {
    font-weight: bolder;
}

dd {
    margin-bottom: var(--base-spacing-unit);
}