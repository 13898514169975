.u-text-left { text-align: left; }
.u-text-center { text-align: center; }
.u-text-right { text-align: right; }

@media (--palm) {
    .u-text-left-palm { text-align: left; }
    .u-text-center-palm { text-align: center; }
    .u-text-right-palm { text-align: right; }
}

@media (--lap) {
    .u-text-left-lap { text-align: left; }
    .u-text-center-lap { text-align: center; }
    .u-text-right-lap { text-align: right; }
}

@media (--lap-and-up) {
    .u-text-left-lap-and-up { text-align: left; }
    .u-text-center-lap-and-up { text-align: center; }
    .u-text-right-lap-and-up { text-align: right; }
}

@media (--portable) {
    .u-text-left-portable { text-align: left; }
    .u-text-center-portable { text-align: center; }
    .u-text-right-portable { text-align: right; }
}

@media (--desk) {
    .u-text-left-desk { text-align: left; }
    .u-text-center-desk { text-align: center; }
    .u-text-right-desk { text-align: right; }
}