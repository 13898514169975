.o-list {}

.o-list--unstyled {
    margin: 0;
    padding: 0;
    list-style: none;
}

.o-list--arrow {
    /*margin: 0;
    padding: 0;*/
    list-style: none;

    li:before {
        content: '';
        width: 0;
        height: 0;
        display: inline-block;
        position: relative;
        margin-left: -1rem;
        border-right: 5px solid transparent;
        border-left: 5px solid var(--color-brand);
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
    }
}

.o-list--checks {
    /*margin: 0;
    padding: 0;*/
    list-style: none;

    li:before {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
        content: '';
        width: 1em;
        height: 1em;
        display: inline-block;
        position: relative;
        margin-left: -1.5em;
        background-color: var(--color-brand);
        background-size: 75%;
        background-repeat: no-repeat;
        background-position: 50%;
        border-radius: 1em;
        top: .125em;
        margin-right: .25em;
    }
}



.o-list--focus {
    color: var(--color-brand);
    font-size: 18px/rem;
    font-weight: bold;
    line-height: 2;
}