.o-animate {

}

.o-animate--fade-out {
    transition: opacity .3s, ease-out;
    &:hover {
        opacity: .5;
    }
}

.o-animate--move {
    transition: all .8s, ease-in-out;
}

.o-animate--overlay {
    position: relative;
    transition: all .25s, ease;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }
    &:hover:after{
        transition: all .25s, ease;
        background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
        background-color: rgba(0, 0, 0, 0.5);
    }
}