.o-table-borderless {
    thead {
        tr {
            background-color: transparent;
        }

        tr th { border-top: 0; }
    }

    th,
    td {
        border-left: 0;
        border-right: 0;
    }
}
