a {
    text-decoration: none;
    color: var(--color-link);
/*    border-bottom: 2px solid rgba(0,0,0,0.25);*/
    transition: all .25s ease-in-out;

    &:hover,
    &:focus {
    	/*border-bottom: 2px solid var(--color-link-hover);*/
        color: var(--color-link-hover);
    }

}
