.u-valign-center { lost-align: vertical center; }

@media (--palm) {
    .u-valign-center-palm { lost-align: vertical center; }
}

@media (--lap) {
    .u-valign-center-lap { lost-align: vertical center; }
}

@media (--lap-and-up) {
    .u-valign-center-lap-and-up { lost-align: vertical center; }
}

@media (--portable) {
    .u-valign-portable { lost-align: vertical center; }
}

@media (--desk) {
    .u-valign-desk { lost-align: vertical center; }
}