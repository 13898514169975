/*
   GLOBAL VARIABLES
   Add your variables to the :root selector.
   Then reference them in your other css modules like so
   :root {
      --spacing-small: 32px;
   }
   .someClass {
     padding: var(--spacing-small)
   }
*/

@font-face {
    font-family: 'pluto-sans_medium';
    src: url('../fonts/plutosansmedium-webfont.woff2') format('woff2'),
         url('../fonts/plutosansmedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pluto-sans_light';
    src: url('../fonts/plutosanslight-webfont.woff2') format('woff2'),
         url('../fonts/plutosanslight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
    --base-font-size:    16px/rem;
    --base-line-height:  24px/rem;
    --base-radius:       .5em;

    --base-spacing-unit: var(--base-line-height);
    --font-primary: 'pluto-sans_light', sans-serif;
    --font-secondary:'Merriweather', 'Times New Roman', serif;
    --font-headings: 'pluto-sans_medium', sans-serif;
    --font-headings-alt: 'pluto-sans_light', sans-serif;
}


@custom-media --palm screen and (max-width: 44.9375em);
@custom-media --lap screen and (width >= 45em) and (width <= 63.9375em);
@custom-media --lap-and-up screen and (min-width: 45em);
@custom-media --portable screen and (max-width: 63.9375em);
@custom-media --desk screen and (min-width: 64em);
@custom-media --sofa screen and (min-width: 80em);

/*
@media (--palm) {

}
*/