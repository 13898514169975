.t-bgc-brand {
    background-color: var(--color-brand);
}

.t-bgc-brand-shadow {
    background-color: var(--color-brand-shadow);
}

.t-bgc-brand-alt {
    background-color: var(--color-brand-alt);
}

.t-bgc-brand-highlight {
    background-color: var(--color-brand-highlight);
}

.t-bgc-gray {
    background-color: var(--color-gray);
}

.t-bgc-gray-dark {
    background-color: var(--color-gray-dark);
}

.t-bgc-gray-light {
    background-color: var(--color-gray-light);
}

.t-bgc-gray-soft {
    background-color: var(--color-gray-soft);
}

