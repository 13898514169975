.c-page-carousel {
    background-color: var(--color-brand);
    text-align: center;


}

.carousel-cell {
	@media (--palm) {
    	padding-top: 1.5rem;
    	padding-bottom: 2.5rem;
    }
    @media (--lap) {
    	padding-top: 3rem;
    	padding-bottom: 4rem;
    }
    @media (--desk) {
    	padding-top: 3rem;
    	padding-bottom: 4rem;
    }
}

	.is-selected .c-page-carousel__title-wrap {
  		animation: bounce 1000ms linear both;
		animation-delay: .5s;
	}
	.is-selected .c-page-carousel__cta {
		animation: bounce 1000ms linear both;
		animation-delay: .75s;
	}

	@keyframes bounce {
		0% { transform: matrix3d(0, 0, 0, 0, 0, 0.25, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 0; }
	    5.81% { transform: matrix3d(0.422, 0, 0, 0, 0, 0.528, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    7.61% { transform: matrix3d(0.536, 0, 0, 0, 0, 0.624, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    11.41% { transform: matrix3d(0.738, 0, 0, 0, 0, 0.812, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    11.61% { transform: matrix3d(0.747, 0, 0, 0, 0, 0.821, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    15.12% { transform: matrix3d(0.881, 0, 0, 0, 0, 0.954, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    17.42% { transform: matrix3d(0.946, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    18.92% { transform: matrix3d(0.98, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    22.72% { transform: matrix3d(1.038, 0, 0, 0, 0, 1.091, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    23.12% { transform: matrix3d(1.042, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    30.23% { transform: matrix3d(1.072, 0, 0, 0, 0, 1.087, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    30.33% { transform: matrix3d(1.072, 0, 0, 0, 0, 1.087, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    37.44% { transform: matrix3d(1.057, 0, 0, 0, 0, 1.045, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    44.54% { transform: matrix3d(1.032, 0, 0, 0, 0, 1.013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    50.25% { transform: matrix3d(1.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    51.65% { transform: matrix3d(1.012, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    70.27% { transform: matrix3d(0.997, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    80.28% { transform: matrix3d(0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1; }
	}




	.c-page-carousel__title-wrap {}
	.c-page-carousel__title {
		background: var(--color-gold);
	    display: inline;
	    line-height: 2;
	    text-transform: uppercase;
	    font-weight: bold;
	    padding: 0.25em;
	    box-decoration-break: clone;

	    @media (--palm) {
	    	font-size: 2rem;
	    }
	    @media (--lap) {
	    	font-size: 3rem;
	    }
	    @media (--desk) {
	    	font-size: 4rem;
	    }
	}

	.c-page-carousel__cta {
		display: block;

		@media (--palm) {
	    	margin-top: 1em;
	    }
	    @media (--lap) {
	    	margin-top: 1em;
	    }
	    @media (--desk) {
	    	margin-top: 1em;
	    }
	}

