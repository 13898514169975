h1 {
    color: var(--color-brand-alt);
    font-family: var(--font-headings);
    font-size: 26px/rem;
    font-weight: normal;
    line-height: 1.25;
}

h2 {
    color: var(--color-brand-alt);
    font-family: var(--font-headings);
    font-size: 24px/rem;
    font-weight: normal;
    line-height: 1.25;
}

h3 {
    color: var(--color-brand-alt);
    font-family: var(--font-headings);
    font-size: 22px/rem;
    font-weight: normal;
    line-height: 1.25;
}

h4 {
    color: var(--color-brand-alt);
    font-family: var(--font-headings);
    font-weight: normal;
    font-size: 20px/rem;
}

h5 {
    color: var(--color-brand-alt);
    font-family: var(--font-headings);
    font-weight: normal;
    font-size: 18px/rem;
}

h6 {
    color: var(--color-brand-alt);
    font-family: var(--font-headings-alt);
    font-weight: normal;
    font-size: 18px/rem;
}

