.c-box {
    border: .125em solid var(--color-gray);
    border-radius: .25em;
    margin-bottom: 2em;
    padding: 1rem;
    padding-bottom: 0;

    hyphens: auto;
}

    .c-box__heading {
        font-weight: 400;
        font-size: 1.25rem;
    }