.o-form {}

.o-from__potty {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.o-form__group {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.o-form__group.is-required {
    .o-form__control-label:after {
        content: attr(data-message);
        color: var(--color-gray);
        float: right;
        font-size: 80%;
    }
}

.o-form__group.is-optional {
    .o-form__control-label:after {
        content: attr(data-message);
        color: var(--color-gray);
        float: right;
        font-size: 80%;
    }
}


.o-form__control-label {
    font-weight: bold;
    display: block;
    position: relative;
}
.o-form__control {
    display: block;
    width: 100%;
    border: .125em solid var(--color-gray);
    padding: .75rem;
    border-radius: .25em;

    &:hover {
        border-color: var(--color-brand-highlight);
    }

    &:focus {
        outline: none;
        border-color: var(--color-brand-shadow);
    }
}


/*
 * Checkboxes and radios
 */

.o-control {
  position: relative;
  display: inline-block;
  color: #555;
  cursor: pointer;
}
.o-control input {
  position: absolute;
  opacity: 0;
  z-index: -1; /* Put the input behind the label so it doesn't overlay text */
}
.o-control__indicator {
  position: absolute;
  top: .25rem;
  left: 0;
  display: block;
  width:  1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 65%;
  color: #eee;
  text-align: center;
  background-color: #eee;
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.o-control:hover .o-control__indicator {
  color: #fff;
  background-color: #ccc;
}
*/

/* Focus */
.o-control input:focus ~ .o-control__indicator {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Checked state */
.o-control input:checked ~ .o-control__indicator {
  color: #fff;
  background-color: #0074d9;
}

/* Active */
.o-control input:active ~ .o-control__indicator {
  color: #fff;
  background-color: #84c6ff;
}

/* Checkbox modifiers */
.o-control--checkbox {
  padding-left: 1.5rem;
}
.o-control--checkbox .o-control__indicator {
  border-radius: .25rem;
}
.o-control--checkbox input:checked ~ .o-control__indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
}

/* Radio modifiers */
.o-control--radio {
  padding-left: 1.5rem;
}
.o-control--radio .o-control__indicator {
  border-radius: 50%;
}
.o-control--radio input:checked ~ .o-control__indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
}

/* Alternately, use another character */
.o-control--x input:checked ~ .o-control__indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xLjQsMEwwLDEuNGwwLjcsMC43bDEuOCwxLjhMMC43LDUuN0wwLDYuNGwxLjQsMS40bDAuNy0wLjdsMS44LTEuOGwxLjgsMS44bDAuNywwLjdsMS40LTEuNEw3LjEsNS43DQoJTDUuMywzLjlsMS44LTEuOGwwLjctMC43TDYuNCwwTDUuNywwLjdMMy45LDIuNUwyLjEsMC43QzIuMSwwLjcsMS40LDAsMS40LDB6Ii8+DQo8L3N2Zz4NCg==);
}
.o-control--dash input:checked ~ .o-control__indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDN2Mmg4VjNIMHoiLz4NCjwvc3ZnPg0K)
}



/*
 * Select
 */

.o-select {
  position: relative;
  display: inline-block;
  color: #555;
}
.o-select select {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: .5rem 2.25rem .5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 0;
  border-radius: .25rem;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
/* Undo the Firefox inner focus ring */
.o-select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
/* Dropdown arrow */
.o-select:after {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  margin-top: -.15rem;
  pointer-events: none;
  border-top: .35rem solid;
  border-right: .35rem solid transparent;
  border-bottom: .35rem solid transparent;
  border-left: .35rem solid transparent;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.o-select select:hover {
  background-color: #ddd;
}
*/

/* Focus */
.o-select select:focus {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Active/open */
.o-select select:active {
  color: #fff;
  background-color: #0074d9;
}

/* Hide the arrow in IE10 and up */
.o-select select::-ms-expand {
  display: none;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
  /* Firefox hack to hide the arrow */
  .o-select select {
    text-indent: 0.01px;
    text-overflow: '';
    padding-right: 1rem;
  }

  /* <option> elements inherit styles from <select>, so reset them. */
  .o-select option {
    background-color: #fff;
  }
}

/* IE9 hack to hide the arrow */
@media screen and (min-width:0\0) {
  .o-select select {
    z-index: 1;
    padding: .5rem 1.5rem .5rem 1rem;
  }
  .o-select:after {
    z-index: 5;
  }
  .o-select:before {
    position: absolute;
    top: 0;
    right: 1rem;
    bottom: 0;
    z-index: 2;
    content: "";
    display: block;
    width: 1.5rem;
    background-color: #eee;
  }
  .o-select select:hover,
  .o-select select:focus,
  .o-select select:active {
    color: #555;
    background-color: #eee;
  }
}



/*
 * File
 */

.o-file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
}
.o-file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}
.o-file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: .075rem solid #ddd;
  border-radius: .25rem;
  box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.o-file-custom:after {
  content: "Choose file...";
}
.o-file-custom:before {
  position: absolute;
  top: -.075rem;
  right: -.075rem;
  bottom: -.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: .075rem solid #ddd;
  border-radius: 0 .25rem .25rem 0;
}

/* Focus */
.o-file input:focus ~ .file-custom {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}



/*
 * Progress
 */

.o-progress {
  display: inline-block;
  height: 1rem;
}
.o-progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  /* Remove Firefox and Opera border */
  border: 0;
  /* IE10 uses `color` to set the bar background-color */
  color: #0074d9;
}
.o-progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: .2rem;
}
.o-progress[value]::-webkit-progress-value {
  background-color: #0074d9;
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}
.o-progress[value="100"]::-webkit-progress-value {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

/* Firefox styles must be entirely separate or it busts Webkit styles. */
@-moz-document url-prefix() {
  .o-progress[value] {
    background-color: #eee;
    border-radius: .2rem;
  }
  .o-progress[value]::-moz-progress-bar {
    background-color: #0074d9;
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
  }
  .o-progress[value="100"]::-moz-progress-bar {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
  }
}

/* IE9 hacks to accompany custom markup. We don't need to scope this via media queries, but I feel better doing it anyway. */
@media screen and (min-width:0\0) {
  .o-progress {
    background-color: #eee;
    border-radius: .2rem;
  }
  .o-progress-bar {
    display: inline-block;
    height: 1rem;
    text-indent: -999rem; /* Simulate hiding of value as in native `<progress>` */
    background-color: #0074d9;
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
  }
  .progress[width="100%"] {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
  }
}


/*
 * Control layouts
 */

.o-controls--stacked {
  margin: 1rem 0;
}
.o-controls--stacked .o-control,
.o-controls--stacked .o-progress {
  display: block;
}
.o-controls--stacked .o-control + .o-control,
.o-controls--stacked .o-progress + .o-progress {
  margin-top: .5rem;
}

.o-controls--inline {
  margin: 1rem 0;
}
.o-controls--inline .o-control {
  display: inline-block;
  height: 1rem;
}
.o-controls--inline .o-control + .o-control {
  margin-left: 1rem;
}