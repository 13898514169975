.c-page-hero {

	background-size: cover;
	@media (--palm) {
    	padding-top: 1.5rem;
    	padding-bottom: 2rem;
    }
    @media (--lap) {
    	height: 20vh;
    }
    @media (--desk) {
    	height: 20vh;
    }
}

