table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1rem 0;
    min-width: 100%;

    thead {
        tr {
            background-color: var(--color-brand-highlight);
            color: var(--color-white);
        }
        th {
            text-align: left;
        }
    }

    tbody {
        th {
            font-weight: normal;
        }
    }

    th,
    td {
        border: 1px solid var(--color-brand);
        padding: .5rem;
    }
}
