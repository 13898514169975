
.c-gallery {
    lost-flex-container: row;

}

.c-gallery__item {
    margin-bottom: 30px;
}


@media (--palm) {

}

@media (--lap-and-up) {


    .c-gallery__item--1 {
        lost-column: 2/3 flex;
        margin-right: 30px !important;
    }

    .c-gallery__item--2 {
        lost-column: 1/3 flex;
        margin-right: 0 !important;
    }

    .c-gallery__item--3 {
        lost-column: 1/3 flex;
        margin-right: 30px !important;
    }

    .c-gallery__item--4 {
        lost-column: 2/3 flex;
        margin-right: 0 !important;
    }

    .c-gallery__image {
        img {
            object-fit: cover;
            width: 100%;
            height: 265px;
        }
    }



}