.c-nav {

    @media (--lap-and-up) {
        /*text-align: center;*/
        max-width: 63em;
        margin-right: auto;
        margin-left:  auto;
        padding-right: 1rem;
        padding-left:  1rem;
    }
}

.c-nav__item {
    @media (--palm) {
        display: block;
    }
    @media (--lap-and-up) {
        display: inline-block;
    }
}
.c-nav__link {
    display: block;
    padding: 16px/rem 24px/rem;
    border: none;

    &:hover,
    &:focus {
        border: none;
    }
}

.c-nav--list {

}

.c-site-nav {
    background-color: var(--color-brand-alt);
    @media (--lap-and-up) {
        border-bottom: .25em solid var(--color-brand);
    }

    .c-nav__link {
        color: var(--color-white);
    }

    @media (--palm) {
        .c-nav__link:hover,
        .c-nav__link:focus {
                background-color: var(--color-brand);
        }
        .c-nav__link--active {
                background-color: var(--color-brand);
        }
    }

    @media (--lap-and-up) {
        .c-nav__item:hover > .c-nav__link,
        .c-nav__item:focus > .c-nav__link,
        .c-nav__link:hover,
        .c-nav__link:focus {
                background-color: var(--color-brand);
        }
        .c-nav__link--current {
                background-color: var(--color-brand);
        }
    }


    .c-nav__link--current .c-nav__link--current {
        background-color: var(--color-brand);
    }
}


.o-flyout__content {

    @media (--palm) {
        background-color: var(--color-brand-alt-highlight);
    }
    @media (--lap-and-up) {
        background-color: var(--color-brand-highlight);
    }
    margin:0;
    padding: 0;
    list-style: none;
    text-align: left;
    font-size: 80%;
    min-width: 20em;

    .c-nav__item,
    .c-nav__link {
        display: block;
    }
}

@media (--palm) {

.c-site-nav[aria-hidden="true"] {
  display: none;
}
}

.c-nav {


}