/*------------------------------------*\
    $FLYOUT
\*------------------------------------*/
/**
 * Flyouts are pieces of content that fly out of a parent when said parent is
 * hovered. They typically appear bottom-left of the parent.
 *
   <div class=flyout>
       Foo
       <div class=flyout__content>
           <h1>Lorem</h1>
           <p>Ipsum</p>
       </div>
   </div>
 *
 * Extend these objects in your theme stylesheet.
 *
 * Demo: jsfiddle.net/inuitcss/B52HG
 *
 */

@media (--lap-and-up) {
.o-flyout,
.o-flyout--alt{
    position:relative;
}
    .o-flyout__content{
        /**
         * Position the flyouts off-screen. This is typically better than
         * `display:none;`.
         */
        position:absolute;
        top:100%;
        left:-99999px;
        z-index: 1000;

        opacity: 0;
        transition: opacity .125s linear;
    }

    /**
     * Bring the flyouts into view when you hover their parents.
     * Two different types of flyout; ‘regular’ (`.o-flyout`) and ‘alternative’
     * (`.o-flyout--alt`).
     */
    .o-flyout:hover,
    .o-flyout:focus,
    .o-flyout--alt:hover,
    .o-flyout--alt:focus{
    }

    /**
     * Regular flyouts sit all the way from the top, flush left.
     */
    .o-flyout:hover > .o-flyout__content,
    .o-flyout:focus > .o-flyout__content {
        left:0;

        opacity: 1;
        transition: opacity .125s linear;
    }

    /**
     * Alternative flyouts sit all the way from the left, flush top.
     */
    .o-flyout--alt:hover > .o-flyout__content,
    .o-flyout--alt:focus > .o-flyout__content{
        top:0;
        left:100%;
    }
}

