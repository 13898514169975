.c-btn {
    display: inline-block;
    font-weight: 600;
    padding-top: .75em;
    padding-right: 1.5em;
    padding-bottom: .75em;
    padding-left: 1.5em;
    text-decoration: none;
    background-color: var(--color-brand);
    color: var(--color-white);
    border-radius: var(--base-radius);
    border: 0;
    box-shadow: inset 0 -.25em 0 0 rgba(0,0,0,0.25);
    outline: none;
    transition: all .25s ease;

    &:hover,
    &:focus {
        background-color: var(--color-brand-highlight);
        color: var(--color-white);
    }

}

.c-btn--primary {
    background-color: var(--color-brand);
    color: var(--color-white);

    &:hover,
    &:focus {
        background-color: var(--color-brand-highlight);
        box-shadow: inset 0 -.125em 0 0 rgba(0,0,0,0.25);
        color: var(--color-white);
    }
}

.c-btn--secondary {
    background-color: var(--color-gold);
    color: var(--color-brand-alt);

    &:hover,
    &:focus {
        background-color: var(--color-gold-highlight);
        box-shadow: inset 0 -.125em 0 0 rgba(0,0,0,0.25);
        color: var(--color-brand-shadow);
    }
}

.c-btn--wide {
    width: 100%;
    display: block;
}

.c-btn--block {
    border-radius: 0;
    box-shadow: none;
}

.c-btn--large {
    font-size: 1.25em;
}


.c-btn--small {
    padding-top: .5rem;
    padding-right: .75rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
}