.o-wrapper {
    max-width: 63em;
    margin-right: auto;
    margin-left:  auto;
    padding-right: 1rem;
    padding-left:  1rem;
}

.o-wrapper--narrow {
    max-width: 57em;
}
