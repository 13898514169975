.c-page-footer {
    padding-top: 4rem;
}


.c-page-footer__micro {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}


