html {
    font-size: 100%;
    line-height: 1.5;
    background-color: #fff;
}

body {
    color: var(--color-text);
    font-family: var(--font-primary);
}
