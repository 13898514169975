h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
    /*margin-bottom: var(--base-spacing-unit);*/
}

ul
ol {
    margin-left: var(--base-spacing-unit);
}

small {
    font-size: 85%;
}