.c-page-foot {

    position: relative;

    @media (--palm) {
        border-bottom: 2.5rem solid var(--color-brand);
        .o-list {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
    @media (--lap-and-up) {
        border-bottom: 7.5rem solid var(--color-brand);
        img {
            max-width: 535px;
            margin-left: -12rem;
            margin-bottom: -3.25rem;
        }
    }




}

.c-page-foot__content {

    ul {
        list-style: none;
        color: var(--color-brand);
        font-size: 18px/rem;
        font-weight: bold;
        line-height: 2;

        li:before {
            content: '';
            width: 0;
            height: 0;
            display: inline-block;
            position: relative;
            margin-left: -1rem;
            border-right: 5px solid transparent;
            border-left: 5px solid var(--color-brand);
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
        }
    }

}