.c-breadcrumb {
    font-size: 80%;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 1em;
}

    .c-breadcrumb__item {
        display: inline-block;

        &:not(.c-breadcrumb__item--last) {
            font-weight: bold;
        }
        &:not(.c-breadcrumb__item--last):after {
            content: "\00b7";
            font-weight: bold;
            margin-right: .5em;
            margin-left: .5em;
        }
    }