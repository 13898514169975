.c-article {
     a {
        box-shadow: inset 0 0 0 0 var(--color-link);
        border-bottom: 2px solid var(--color-link);
        transition: all .25s ease-in-out;
        padding-left: .125rem;
        padding-right: .125rem;
        margin-left: -.125rem;
        margin-right: -.125rem;
    }

    a:hover,
    a:focus {
        box-shadow: inset 0 -20px 0 0 var(--color-link-hover);
        border-bottom: 2px solid var(--color-link);
        color: var(--color-white);
    }
}
    .c-article__title {
        font-size: 2rem;
        margin-bottom: .125em;
    }

    .c-article__title-sub {
        margin-bottom: 1em;
        color: var(--color-link);
    }



.o-content {
    a:link {
        box-shadow: inset 0 -1px 0 0 var(--color-link);
        transition: all .125s ease;
        padding-left: .125rem;
        padding-right: .125rem;
        margin-left: -.125rem;
        margin-right: -.125rem;
    }

    a:hover,
    a:focus {
        box-shadow: inset 0 -18px 0 0 var(--color-link-hover);
        color: var(--color-white);
        transition: all .25s ease;
    }

}

.o-content__lead {

    font-family: "Merriweather", "Georgia", "Times New Roman", serif;
    font-size: 18px/rem;
}


@media (--portable) {
    .o-content {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media (--desk) {
    .o-content {
        padding-top: 4rem;
        margin-right: 3.5rem;
        padding-bottom: 4rem;
        margin-left: 3.5rem;

        > figure {
            margin-top: 2rem;
            margin-right: -3.5rem;
            margin-bottom: 2rem;
            margin-left: -3.5rem;
        }

        .guggenheim__row {
            margin-right: -4.5rem;
            margin-left: -4.5rem;
        }
    }
}

.o-content--lead > p:first-of-type {
    font-size: 1.125rem;
}
.o-content ul,
.o-content ol {
    max-width: 51.5em;
}
